<template>
  <section class="properties" style="width: 100%">
    <div class="property-list-container">
      <Header :details="{ title: 'Calendar', back: null, actions: false }" />
      <div class="calendar">
        <div class="wrapper-content">
          <div style="margin-top: 25px">
            <Select
              v-if="canShowFilters"
              :options="userFiltersDropdown"
              :value="currentAgentId"
              @input="onAgentChange"
            />
          </div>
          <div class="calendar__box">
            <div class="calendar__legend">
              <p class="calendar__legend-title">Labels</p>
              <ul class="">
                <li v-for="item in resources" :key="item.id" class="calendar__legend-item">
                  <span class="calendar__legend__circle" :style="`--bg:${item.eventBackgroundColor}`"></span>
                  <span class="calendar__legend__title">{{ item.title }}</span>
                </li>
              </ul>
            </div>
            <FullCalendar :options="config">
              <template #dayHeaderContent="arg">
                {{ formatDayHeaderDate(arg.date) }}
              </template>
              <template #eventContent="arg">
                <div :style="{ backgroundColor: arg.backgroundColor }">
                  <b>{{ arg.timeText }} </b>
                  <span>{{ arg.event.title }}</span>
                </div>
              </template>
            </FullCalendar>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from "vue";
import modalBus from "../../bus/modal-bus";
import FullCalendar from "@fullcalendar/vue";
import interactionPlugin from "@fullcalendar/interaction";
import momentPlugin from "@fullcalendar/moment";
import resourceCommonPlugin from "@fullcalendar/resource-common";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
//import frLocale from "@fullcalendar/core/locales/fr";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
import { AccountCapabilityCanSeeAgendaFromAll, AccountCapabilityCanSeeOwnAgenda, Capability } from "@/types";
import store from "@/store";
import router from "@/router";
import userCan from "@/helpers/userCan";

export default Vue.extend({
  name: "Calendar",
  components: {
    FullCalendar,
  },

  data() {
    return {
      month: null,
      year: null,
      day: null,
      userFiltersDropdown: [],
      calendarOptions: {},
      fetchedUserDependencies: false, // whether or not the user with it's ID etc are available
    };
  },
  methods: {
    ...mapActions({
      _fetchEvents: "agenda/fetchEvents",
      saveEvent: "agenda/saveEvent",
      setCurrentAgentId: "agenda/setCurrentAgentId",
    }),
    formatDayHeaderDate(date) {
      const dateMoment = moment(date);
      return dateMoment.format("dddd, D");
    },
    async onEventDrop(arg) {
      // TODO: on event DRAG add one day ?
      const start = arg.event.start;
      const end = arg.event.end;
      const startDateMoment = moment(start);
      const asEnd = !!end;
      const endDateMoment = asEnd ? moment(end) : moment(start);
      if (arg.event._def.allDay) {
        endDateMoment.subtract(1, "days");
      }
      const startDateStr = startDateMoment.format("YYYY-MM-DD");
      const endDateStr = endDateMoment.format("YYYY-MM-DD");
      const startTimeStr = startDateMoment.format("HH:mm:ss");
      const endTimeStr = asEnd ? endDateMoment.format("HH:mm:ss") : "00:00:00";
      const event = {
        ...arg.event.extendedProps.backendEvent,
        ...arg.event.extendedProps.backendEventCollaborators,
        from_date: startDateStr,
        to_date: endDateStr,
        from_time: startTimeStr ? startTimeStr : "00:00:00",
        to_time: endTimeStr,
        is_time: !arg.event._def.allDay,
      };
      await this.saveEvent({ event });
    },
    onEventClick(arg) {
      let event = arg.event.extendedProps.backendEvent;
      modalBus.$emit("change", {
        component: "AddEvent",
        data: {
          addClient: true,
          isNewEvent: false,
          event,
        },
      });
      modalBus.$emit("set-size", {
        width: "500px",
        //"margin-top": "0",
        //height: "auto",
      });
      modalBus.$emit("toggle", true);
    },
    onDateSelect(arg) {
      const start = arg.start;
      const end = arg.end;
      const startDateMoment = moment(start);
      const endDateMoment = moment(end);
      if (arg.allDay) {
        endDateMoment.subtract(1, "days");
      }
      const endDateStr = endDateMoment.format("YYYY-MM-DD");
      const startDateStr = startDateMoment.format("YYYY-MM-DD");
      const startTimeStr = moment(start).format("HH:mm") + ":00";
      const endTimeStr = moment(end).format("HH:mm") + ":00";
      const id = `${startDateStr}-${startTimeStr}-${endDateStr}-${endTimeStr}-${Math.floor(Math.random() * 1000) + 1}`;

      modalBus.$emit("change", {
        component: "AddEvent",
        data: {
          addClient: true,
          isNewEvent: true,
          event: {
            id,
            is_time: false,
            from_date: startDateStr,
            from_time: startTimeStr,
            to_date: endDateStr,
            to_time: endTimeStr,
            client: null,
            deal: null,
            description: null,
            is_done: false,
            properties: [],
            token: null,
            is_in_agenda: true,
            //type: "email",
          },
        },
      });

      modalBus.$emit("set-size", {
        width: "500px",
        //"margin-top": "100px",
        //height: "auto",
      });

      modalBus.$emit("toggle", true);
    },
    onAgentChange(value) {
      this.setCurrentAgentId(value);
    },
    fetchEvents() {
      // If user have only access to its own events, filter
      let from = "";
      if (!this.canShowFilters) {
        from = this.user.id;
      }
      this._fetchEvents({ from });
    },
    fetchAndSetupUserFilterActions() {
      if (this.canShowFilters) {
        this.$axios.get("/api/users").then((res) => {
          let userFiltersDropdown = res.data.map((user) => {
            return {
              name: `${user.first_name} ${user.last_name}${user.id === this.user.id ? " ( myself )" : ""}`,
              value: user.id,
            };
          });
          userFiltersDropdown.unshift({
            name: "All",
            value: null,
          });
          this.userFiltersDropdown = userFiltersDropdown;
        });
      }
    },
    fetchUserDependencies() {
      if (this.fetchedUserDependencies) {
        return;
      }
      console.log("loadUserDependencies");
      this.fetchedUserDependencies = true;
      this.fetchEvents();
      this.fetchAndSetupUserFilterActions();
    },
  },
  watch: {
    //@ts-ignore
    user: function(user, oldUser) {
      if (user && parseInt(user.id) > 0) {
        this.fetchUserDependencies();
      }
    },
  },
  computed: {
    ...mapGetters({
      events: "agenda/events",
      resources: "agenda/resources",
      currentAgentId: "agenda/currentAgentId",
      eventsFromCurrentAgent: "agenda/eventsFromCurrentAgent",
    }),
    user() {
      return this.$store.state.global.user;
    },
    canShowFilters() {
      if (!this.user || !this.user.capabilities.length) {
        return false;
      }
      return (
        this.user.capabilities.includes(AccountCapabilityCanSeeOwnAgenda) &&
        this.user.capabilities.includes(AccountCapabilityCanSeeAgendaFromAll)
      );
    },
    config() {
      return {
        ...this.configOptions,
        ...this.eventHandlers,
      };
    },
    configOptions() {
      return {
        schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
        //locale: frLocale,
        plugins: [interactionPlugin, resourceCommonPlugin, dayGridPlugin, timeGridPlugin, momentPlugin],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "timeGridDay,timeGridWeek,dayGridMonth",
        },
        initialView: "timeGridWeek",
        editable: true,
        disableResizing: true,
        selectable: true,
        firstDay: 1,
        dayHeaderFormat: { day: "numeric", weekday: "long", month: "numeric", omitCommas: false },
        slotMinTime: "00:00:00",
        slotMaxTime: "23:59:00",
        slotLabelInterval: "01:00",
        slotLabelFormat: {
          hour: "numeric",
          minute: "2-digit",
          hourCycle: "h23",
          omitZeroMinute: false,
          meridiem: false,
        },
        eventTimeFormat: {
          hour: "numeric",
          minute: "2-digit",
          hourCycle: "h23",
          omitZeroMinute: false,
          meridiem: false,
        },
        events: this.eventsFromCurrentAgent,
      };
    },
    eventHandlers() {
      return {
        //dateClick: this.onDateClick,
        eventClick: this.onEventClick,
        eventDrop: this.onEventDrop,
        eventResize: this.onEventDrop,
        select: this.onDateSelect,
      };
    },
  },
  created() {
    if (!this.fetchedUserDependencies && this.user && this.user.id) {
      this.fetchUserDependencies();
    }
    modalBus.$on("add-event", (el) => {
      if (el) {
        const index = this.events.findIndex((ev) => {
          return ev.id === el.id;
        });

        if (index === -1) this.events.push(el);
      }
    });
  },
});
</script>

<style scoped></style>
